<template>
  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <h1 class="title">{{ currentId ? 'Редактирование переговорной' : 'Новая переговорная' }}</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div>
        <div class="form-add pos-r z0 p-20 grid ggap-30" id="form-add">
          <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
          <div class="grid ggap-20" :class="[isMobile ? 'gtc-1' : 'gtc-2']">
            <FormInput type="text" label="Название переговорной" required :maxlength="100" v-model="formData.name" />
            <FormInput type="text" label="Локация" required v-model="formData.location" />
          </div>
          <div class="grid gtc-1 ggap-20">
            <FormTags
              type="text"
              label="Теги"
              tagsName="meetingRoomsTags"
              dispatcher="GET_MEETING_ROOMS_TAGS"
              v-model="formData.tags"
            />
          </div>
          <div class="grid gtc-1 ggap-20">
            <FormSelect
              label="Вместимость"
              required
              class="sm white"
              :options="сapacityOptions"
              v-model="formData.capacity"
            />
          </div>
          <div class="grid gtc-1 ggap-20" v-if="formData.capacity">
            <FormSelect
              label="Макс. Вместимость"
              required
              class="sm white"
              :options="maxCapacityOptions"
              v-model="formData.max_capacity"
            />
          </div>
          <hr />
          <div class="grid ggap-20" :class="[isMobile ? 'gtc-1' : 'gtc-2']">
            <FormSingleFileGrid
              keyName="image"
              title="Загрузите основное фото"
              :required="currentId ? false : true"
              v-model="formData.files"
              acceptTypes=".jpg,.jpeg,.png"
            />
            <FormSingleFileGrid
              keyName="secondary_image"
              :required="currentId ? false : true"
              title="Загрузите дополнительное фото"
              v-model="formData.files"
              acceptTypes=".jpg,.jpeg,.png"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c ggap-20" :class="{ 'form-add__disabled': isLoad }">
        <div class="flex ai-c ggap-10 ml-auto">
          <button class="btn transparent-grey" @click.prevent="closeAction">Отмена</button>
          <button class="btn primary" @click.prevent="submitAction">
            {{ currentId ? 'Сохранить изменения' : 'Отправить' }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useStore } from 'vuex'
import { defineEmits, defineProps, ref, toRefs, computed } from 'vue'
import { BaseLoad, ModalWrapper, FormInput, FormTags, FormSingleFileGrid, FormSelect } from '@/components'
import { isInvalidForm, $busEmit } from '@/plugins'
import { meetingRooms } from '@/api'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  currentId: {
    type: [Number, String],
    default: 0
  }
})

// Data
const isMobile = computed(() => store.getters.isMobile)
const сapacityOptions = computed(() => {
  const array = []
  for (let i = 2; i <= 30; i++) array.push({ text: i, value: i })
  return array
})
const maxCapacityOptions = computed(() => {
  const array = []
  for (let i = Number(formData.value.capacity) + 2; i <= 40; i++) array.push({ text: i, value: i })
  return array
})
momentDurationFormatSetup(moment)
const { currentId } = toRefs(props)
const store = useStore()
const isLoad = ref(false)
const defaultFormData = {
  name: '',
  location: '',
  tags: [],
  capacity: NaN,
  max_capacity: NaN,
  files: {}
}
const formData = ref({ ...defaultFormData })

// Created
if (currentId.value) getItem()
store.dispatch('helpers/GET_MEETING_ROOMS_TAGS')

// Methods
function getItem() {
  isLoad.value = true
  meetingRooms
    .get(currentId.value)
    .then(({ data }) => {
      formData.value = {
        ...data?.data,
        files: { image: data?.data.image, secondary_image: data?.data?.secondary_image },
        tags: data?.data?.tags
      }
    })
    .finally(() => (isLoad.value = false))
}

function closeAction() {
  emits('close')
  formData.value = { ...defaultFormData }
}

async function submitAction() {
  if (isInvalidForm('form-add')) return
  isLoad.value = true
  const payload = {
    ...formData.value,
    name: formData?.value?.name.toString(),
    image: formData?.value?.files?.image,
    secondary_image: formData?.value?.files?.secondary_image
  }
  if (currentId.value) {
    payload.id = currentId.value
    payload.action = 'update'
  }

  try {
    const changedData = {
      ...payload,
      image: payload.image?.file,
      secondary_image: payload.secondary_image?.file
    }
    const data = await store.dispatch('meetingRooms/POST', payload)
    if (currentId.value) {
      delete changedData.action
      store.commit('meetingRooms/CHANGE_ITEM', { id: currentId.value, payload: changedData })
    } else {
      store.commit('meetingRooms/PUSH_ITEM', data?.data?.data)
    }
    const message = currentId.value
      ? `Переговорная ${payload.name || ''}</a> успешно изменена!`
      : `Новое переговорная ${data.data.name || ''}</a> успешно создана!`
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: message
    })
    $busEmit('updateItem')
    store.dispatch('helpers/GET_USER_EVENTS')
    closeAction()
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
</script>

<style lang="scss" scoped>
.form-add {
  width: 700px;

  &__tags {
    background-color: var(--grey-l);

    &-select {
      flex: auto;

      &-input {
        border: 0;
        background-color: transparent;
        height: 30px;
      }

      &-ddown {
        box-shadow: var(--box-shadow);
      }
    }
  }

  &__disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
}
@media screen and (max-width: 800px) {
  .form-add {
    width: calc(100vw - 40px);
  }
}
</style>
